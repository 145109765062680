<template>
  <div>
    <ExtendedView :tabs="tabs">
      <template v-slot:list="{ item }">
        <WidgetList
          v-if="item && item.key"
          :fixed-filters="fixedFilters(item.key)"
          :load-items-callback="getProcessDisplaySystems"
          :call-to-actions="callToActions"
          @selectWidget="selectDisplay"
        />
      </template>
      <template
        v-slot:profile
      >
        <WidgetProfile
          v-if="selectedProcessDisplaySystem"
          :widget="selectedProcessDisplaySystem"
          :update-call-back="showProcessDisplaySystemWithEdit"
          :change-active-status-callback="updateProcessDisplaySystem"
          :preview-url="'https://order-display.salescloud.is/?uuid=' + selectedProcessDisplaySystem.uuid"
          widget-type="process-display-system"
        />
      </template>
    </ExtendedView>
  </div>
</template>

<script>

import ExtendedView from '@/components/common/ExtendedView'

import WidgetProfile from "@/components/widget/WidgetProfile"
import WidgetList from "@/components/widget/WidgetList"

export default {
	name: 'ProcessDisplaySystems',
	components: {
    WidgetList,
		ExtendedView,
    WidgetProfile
	},
	data() {
		return {
      widgets: [],
			selectedProcessDisplaySystem: null,
		}
	},
	methods: {
    fixedFilters(key) {
      switch (key) {
        case 'active':
          return [
            {
              key: 'active',
              type: 'boolean',
              value: true,
              operator: 'equals'
            }
          ]
        case 'inactive':
          return [
            {
              key: 'active',
              type: 'boolean',
              value: true,
              operator: 'not_equals'
            }
          ]
        default:
          return []
      }
    },
    addProcessDisplaySystem() {
      this.$store.commit('updateDataToMutate', {
        objectToMutate: {
          title: null,
          channels: [],
          location: null,
          posChannels: [],
          categories: [],
          states: []
        },
        formComponent: 'components/processDisplaySystems/ProcessDisplaySystemForm',
        saveCallback: this.createProcessDisplaySystem,
        previewStyle: 'padding-top: 177%;',
        title: this.$t('createWithPreview')
      })
    },
    createProcessDisplaySystem(newPSD) {
      this.$store.dispatch('createProcessDisplaySystem', newPSD).then(result => {
        this.$store.commit('updateActionSuccess', {
          message: this.$t('success'),
          subMessage: this.$t('successfullyCreatedProcessDisplaySystem')
        })
      }).catch(err => {
        this.$store.commit('updateActionError', {
          message: this.$t('error'),
          subMessage: this.$t('couldNotCreateProcessDisplaySystem') + '\n' + err
        })
      })
    },
    updateProcessDisplaySystem(newValues) {
      this.$store.dispatch('updateProcessDisplaySystem', newValues).then(result => {
        this.$store.commit('updateActionSuccess', {
          message: this.$t('success'),
          subMessage: this.$t('successfullyUpdatedProcessDisplaySystem')
        })
      }).catch(err => {
        this.$store.commit('updateActionError', {
          message: this.$t('error'),
          subMessage: this.$t('couldNotUpdateProcessDisplaySystem') + '\n' + err
        })
      })
    },
    showProcessDisplaySystemWithEdit(profile) {
      return this.$store.commit('updateDataToMutate', {
        objectToMutate: profile,
        formComponent: 'components/processDisplaySystems/ProcessDisplaySystemForm',
        saveCallback: this.updateProcessDisplaySystem,
        previewUrl: 'https://order-display.salescloud.is/',
        previewStyle: 'padding-top: 177%;',
        title: this.$t('editWithPreview')
      })
    },
		getProcessDisplaySystems() {
			return this.$store.dispatch('getReferencedProcessDisplaySystems').then(processDisplaySystems => {
        this.widgets = processDisplaySystems
        this.$store.commit('updateAppBarTabs', this.tabs)
        return processDisplaySystems
			})
		},
		selectDisplay(display) {
      this.selectedProcessDisplaySystem = display
		},
	},
	computed: {
    selectedAppBarTab() {
      return this.$store.state.selectedAppBarTab
    },
		callToActions() {
			return [
				{
					title: this.$t('addProcessDisplay'),
					callback: this.addProcessDisplaySystem
				},
			]
		},
    activeCount() {
      if(Array.isArray(this.widgets)) {
        return this.widgets.filter(widget => widget && widget.active).length
      }
      return 0
    },
    inactiveCount() {
      if(Array.isArray(this.widgets)) {
        return this.widgets.filter(widget => widget && !widget.active).length
      }
      return 0
    },
    tabs() {
      const tabs = []

      tabs.push({
        title: 'Active',
        key: 'active',
        badge: {
          content: this.activeCount
        }
      })

      tabs.push({
        title: 'Inactive',
        key: 'inactive',
        badge: {
          content: this.inactiveCount
        }
      })

      return tabs
    },
	},
  watch: {
    widgets(value) {
      if(typeof this.selectedProcessDisplaySystem !== 'undefined' && this.selectedProcessDisplaySystem !== null) {
        const widgetsIndex = value.findIndex(w => w && w.uuid === this.selectedProcessDisplaySystem.uuid)
        if(widgetsIndex >= 0) {
          this.selectedProcessDisplaySystem = value[widgetsIndex]
        }
      }
    },
    selectedAppBarTab(current, previous) {
      if(current !== previous) {
        this.selectedProcessDisplaySystem = null
      }
    }
  },
  created() {
    this.getProcessDisplaySystems()

    this.$store.commit('updateAppBarTabs', this.tabs)
    if (Array.isArray(this.tabs) && this.tabs.length > 0) {
      this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
    }

    this.$store.commit('updateShowAppBar', false)
    this.$store.commit('updateShowSidebar', true)
  },
}
</script>
